import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (

      <header id="header" className="fixed-top ">
        <div className="container d-flex align-items-center justify-content-between">


          <a href="index.html" className="logo"><img src="img/logos/logo-text.png" alt="" className="img-fluid" /></a>

          <nav id="navbar" className="navbar">
            <ul>
              <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
              <li><a className="nav-link scrollto" href="#about">About</a></li>
              <li><a className="nav-link scrollto" href="#features">Features</a></li>
              <li className="dropdown"><a href="#"><span>User</span> <i className="bi bi-chevron-down"></i></a>
                <ul>
                  <li><a href="https://status.aria.services" target="_blank">Service Status</a></li>
                  <li><a href="https://instruct-eric.org/help/collections" target="_blank">Help Guides</a></li>
                </ul>
              </li>
              <li className="dropdown"><a href="#"><span>Developer</span> <i className="bi bi-chevron-down"></i></a>
                <ul>
                  <li><a href="https://gitlab.com/aria-php" target="_blank">Download Libraries</a></li>
                  <li><a href="https://api.aria.services" target="_blank">API Documentation</a></li>
                  <li><a href="https://beta.api.aria.services" target="_blank">API Documentation (BETA)</a></li>
                  <li><a href="/logo.html" target="_blank">Logos</a></li>

                  <li className="dropdown"><a href="#"><span>Legacy API</span> <i className="bi bi-chevron-right"></i></a>
                    <ul>
                      <li><a href="/docs.html" target="_blank">Documentation</a></li>
                      <li><a href="/demo.html" target="_blank">OAuth Demo</a></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
              <li><a className="getstarted scrollto" href="#about">Get Started</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>

    )
  }
}
export default Header; 